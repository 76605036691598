import React from "react";
import JobPosition from "./JobPosition";

const JobSection = (props) => {
  return (
    <div className="flex flex-col">
      <ul>
        {props.openPositions.map((position) => (
          <JobPosition
            section={props.section}
            key={position.id}
            {...position}
          />
        ))}
      </ul>
    </div>
  );
};

export default JobSection;
