import React from "react";

const LinkedinSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="20"
    height="20"
    viewBox="0 0 192 192"
  >
    {
      <g
        fill="none"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
      >
        <path d="M0,192v-192h192v192z" fill="none"></path>
        <g fill="#ffffff">
          <g id="surface1">
            <path d="M156,0h-120c-19.875,0 -36,16.125 -36,36v120c0,19.875 16.125,36 36,36h120c19.875,0 36,-16.125 36,-36v-120c0,-19.875 -16.125,-36 -36,-36zM59.36539,162.98077h-29.82693l-0.17307,-89.30769h29.82692zM43.70192,61.99038h-0.17308c-9.75,0 -16.03846,-6.72115 -16.03846,-15.08653c0,-8.56731 6.49039,-15.0577 16.41347,-15.0577c9.92308,0 16.00961,6.49038 16.21153,15.0577c0,8.36538 -6.31731,15.08653 -16.41346,15.08653zM162.77885,162.98077h-30.08654v-48.51923c0,-11.74039 -3.11538,-19.73077 -13.61538,-19.73077c-8.01923,0 -12.34615,5.39423 -14.42308,10.61538c-0.77885,1.875 -0.98077,4.44231 -0.98077,7.06731v50.56731h-30.23077l-0.17308,-89.30769h30.23077l0.17308,12.60577c3.86538,-5.97116 10.29808,-14.42308 25.70192,-14.42308c19.09616,0 33.37501,12.46154 33.37501,39.25961v51.86539z"></path>
          </g>
        </g>
      </g>
    }
  </svg>
);

// JOB POSITION OBJECT EXAMPLE

// description: "Brindar soporte a usuarios."
// end_date: "2021-10-01"
// id: 1
// id_section: 1
// place: "Bogota, Colombia"
// section: "Help Desk"
// start_date: "2021-08-08"
// status: 1
// title: "2Operador NOC"
// url: "http://linkedin"

const JobPosition = (props) => {
  return (
    <li className="flex flex-col bg-white shadow-md p-8 gap-6">
      <h2 className="flex flex-col uppercase">
        <span className="tracking-tight text-blue-400">{props.section}</span>
        <span className="font-bold tracking-tight text-blue-900 text-lg md:text-xl">
          {props.title}
        </span>
      </h2>

      <p className="flex font-extralight text-base text-gray-500">
        {props.description}
      </p>

      <a
        href={props.url}
        className="text-base flex justify-center font-medium bg-blue-700 hover:bg-blue-600 text-white p-2"
        target="_blank"
        rel="noreferrer"
      >
        <button className="flex gap-2">
          <span>Contact us </span>
          <span className="self-center">{LinkedinSvg}</span>
        </button>
      </a>
    </li>
  );
};

export default JobPosition;
