import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Img from "gatsby-image";
import JobSection from "../components/Job/JobSection";
import { graphql, useStaticQuery } from "gatsby";

const CareersPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "careers-img.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  const [jobsData, setJobsData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`https://api.ipxon.net/public/jobs`)
      .then((response) => response.json())
      .then((resultData) => {
        console.log(resultData);
        setJobsData(resultData.opportunities);
        setLoading(false);
      })
      .catch((error) =>
        alert(
          "Error fetching our job listings, please refresh the page if the error persists!"
        )
      );
  }, []);

  return (
    <main className="font-mulish w-full shadow-inner">
      <title>Conexum - Careers page</title>
      <div>
        <header id="header" className="bg-black h-1/5 transition-all">
          <Navbar />
        </header>
        <section className="relative bg-blue-800">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-hidden">
              <Img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                fluid={imageData}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Conexum's founders"
              />
            </div>
          </div>
          <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
            <div className="lg:pr-8 flex justify-center">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h2 className="flex uppercase text-3xl flex-col md:text-5xl font-bold text-white tracking-tighter">
                  <span>
                    BUILD YOUR <span className="text-blue-300">CAREER</span>
                  </span>
                  <span className="font-light tracking-tighter">
                    AT CONEXUM
                  </span>
                </h2>
                <p className="text-gray-300 pt-4 text-lg">
                  If you feel that you share our Philosophy, Vision and Mission,
                  and you think that you can contribute and enrich our team you
                  are welcome!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-100 shadow-inner">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-4 py-8 md:p-8">
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 mr-3 ..."
                viewBox="0 0 24 24"
              ></svg>
            ) : (
              Object.entries(jobsData).map(([jobSection, jobPositions]) => (
                <JobSection
                  key={jobSection}
                  section={jobSection}
                  openPositions={jobPositions}
                />
              ))
            )}
          </div>
        </section>
        <div className="bg-white">
          <Footer />
        </div>
      </div>
    </main>
  );
};

export default CareersPage;
